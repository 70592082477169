<template>
    <div class="container text-center">
        <img
            src="../assets/autopay.svg"
            class="mt-4"
            alt="Setup AutoPay"
        >
        <h4
            class="mt-5"
            v-html="$t('pages.SetupAutoPay.description')"
        />
        <base-button
            class="mt-5"
            type="button"
            button-classes="btn btn-primary"
            @click="setupAutoPay"
        >
            {{ $t('pages.SetupAutoPay.setupAutoPay') }}
        </base-button>
        <base-button
            class="mt-2"
            button-classes="btn btn-tertiary"
            @click="notNow"
        >
            {{ $t('pages.SetupAutoPay.notNow') }}
        </base-button>
        <div class="mt-1">
            <slot />
        </div>
    </div>
</template>

<script>
    import BaseButton from '@/components/base/BaseButton'
    import { postLoginNavigation } from '@/utils/sharedLogic'
    import { RouteNames } from '../routes/router'
    import { mapMutations } from 'vuex'

    export default {
        name: RouteNames.SETUP_AUTOPAY,
        components: {
            BaseButton,
        },
        mounted() {
            this.enrollAutoPayAndKeepAprDiscountShown()
            this.$logEvent('view_setup_autopay_and_keep_discount')
        },
        methods: {
            ...mapMutations(['enrollAutoPayAndKeepAprDiscountShown']),
            setupAutoPay: function () {
                this.$logEvent('click_setup_autopay_and_keep_discount')
                this.$router.push({ name: RouteNames.ENROLL_IN_AUTOPAY })
            },
            notNow: function () {
                this.$logEvent('click_button_not_now_setup_auto_and_keep_discount')
                postLoginNavigation()
            },
        },
    }
</script>
